.LoadingDots {
  margin: 0 auto;
  width: 70px;
  @apply text-center;
}

.LoadingDots > span {
  @apply text-center;
  @apply inline-block;
  @apply rounded-full;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.LoadingDots .bounce1 {
  animation-delay: -0.32s;
}

.LoadingDots .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
