.NewMessageCardCompact {
  @apply h-40 text-white bg-gradient-to-t from-gblue-700 to-gblue-500;

  & .NewMessageGraphic {
    @apply absolute bottom-0 right-0;
    @media (min-width: 375px) {
      @apply mb-6;
    }
    @screen sm {
      @apply -mb-2;
    }
    & svg {
      transform: rotate(-10deg);
      @apply h-16 mb-4 mr-2;
      @media (min-width: 324px) {
        @apply h-20 mr-1 -mb-2;
      }
      @screen sm {
        @apply h-32 mb-3 mr-2;
      }
    }
  }

  & main {
    @apply flex-grow px-3 py-2;
    & h2 {
      @apply text-sm font-semibold truncate;
      @screen sm {
        @apply text-lg;
      }
    }
    & h4 {
      @apply font-semibold text-2xs text-gblue-100;
      @screen sm {
        @apply text-xs;
      }
    }
  }

  & footer {
    @apply z-10 px-4 py-3 font-semibold text-right text-white bg-gblue-500;

    @apply text-xs truncate;
    @screen sm {
      @apply text-base;
    }
  }
}
