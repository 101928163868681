.ButtonBase {
  @apply rounded cursor-pointer select-none shadow-sm;
  @apply flex items-center justify-center;

  @apply text-sm font-semibold;
  @apply px-4 py-2;

  &:hover {
    filter: brightness(105%);
  }

  &:active {
    @apply shadow-none;
    filter: brightness(95%);
  }

  &:disabled {
    @apply opacity-50;
  }
}
