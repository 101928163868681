.LoadingOverlay[class] {
  @apply fixed z-50 bottom-0 p-4 flex items-end justify-center select-none;
  & .LoadingBar {
    @apply rounded flex py-2 pl-6;
    @apply bg-gnavy-800 text-white text-xs;
    @apply font-semibold;
    & .LoadingDots {
      @apply w-1 h-1 mb-px mr-1 bg-white;
    }
  }
}
