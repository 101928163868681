/* purgecss start ignore */

.Toastify__toast-container {
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 9999px);
  /* @apply rounded-lg border-b border-r border-gray-500 shadow-xl; */
  @apply fixed top-0 right-0;
  @apply m-3 mr-5;
  @apply w-72 h-auto;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100vw;
    @apply m-0;
  }
}

.Toastify__toast {
  @apply rounded shadow-2xl;
  @apply select-none cursor-pointer;
  @apply overflow-hidden;
  @apply relative flex justify-between items-start;
  @apply p-2;
  min-height: 64px;
  max-height: 800px;
}

.Toastify__toast--default,
.Toastify__toast--info,
.Toastify__toast--success,
.Toastify__toast--warning {
  @apply bg-gnavy-300 text-gnavy-900;
  .Toastify__close-button {
    @apply text-gnavy-900;
  }
  .Toastify__progress-bar {
    @apply bg-gnavy-400;
  }
}

.Toastify__toast--error {
  @apply bg-red-300 text-red-900;
  .Toastify__close-button {
    @apply text-red-900;
  }
  .Toastify__progress-bar {
    @apply bg-red-400;
  }
}

.Toastify__toast-body {
  @apply h-auto px-1 mb-2;
  @apply text-sm font-semibold break-words;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}

.Toastify__close-button {
  @apply h-5 w-5 rounded-full flex-shrink-0 -mt-2 -mr-2;
  @apply flex items-center justify-center;
  @apply font-semibold;
  @apply bg-transparent;
  @apply outline-none border-none;
  @apply cursor-pointer;
  @apply text-gray-500;
  transition: 0.3s ease;
}
.Toastify__close-button--default {
  @apply text-black opacity-25;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  @apply opacity-100;
}

.Toastify__progress-bar {
  @apply rounded-bl-lg;
  @apply absolute bottom-0 left-0 w-full h-2;
  z-index: 9999;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}

/******** Animations ********/

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

/* purgecss end ignore */
