.ModalList {
  @apply rounded-b-lg;

  .EditIcon {
    @apply w-5 h-5 text-gray-400 flex-shrink-0;
  }

  li {
    @apply text-sm;
    @apply border-b border-gray-200;
  }

  li:first-child {
    @apply rounded-t;
  }

  li:nth-child(even) {
    @apply bg-white;
  }

  li:nth-child(odd) {
    @apply bg-gray-100;
  }

  li:last-child {
    @apply border-b-0 rounded-b-lg;
  }

  li:hover {
    @apply bg-blue-100;
    .EditIcon {
      @apply text-blue-400;
    }
  }
}
