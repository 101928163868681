.AdminMenuButton {
  @apply hidden;
  @screen md {
    @apply flex;
  }

  @apply px-1 py-1 shadow-none items-center truncate flex-grow;
  @apply bg-gnavy-100 text-gnavy-700;

  @apply text-xs;
  @screen sm {
    @apply text-sm px-2;
  }

  & svg {
    @apply w-3 h-3 mr-px flex-shrink-0;
    margin-bottom: 1px;
    @screen sm {
      @apply w-4 h-4;
    }
  }
}
