.MyLoansPage {
  @apply w-full grid gap-3 p-3;

  @apply grid-rows-1;

  @media (min-width: 375px) {
    @apply grid-cols-1;
  }
  @media (min-width: 834px) {
    @apply grid-cols-2;
  }
}
