/* purgecss start ignore */

.react-select__control {
  @apply border-2 border-gray-400 bg-white rounded shadow-none !important;
}

.react-select__control:hover:not(.react-select__control--menu-is-open) {
  @apply border-blue-500 !important;
}

.react-select__control--menu-is-open {
  @apply border-blue-400 border-2 outline-none shadow-none !important;
}

.react-select__value-container {
  @apply pl-3;
}

.react-select__placeholder {
}

.react-select__indicator-separator {
  @apply hidden !important;
}
.react-select__indicators {
  @apply bg-gray-400 h-full rounded-none !important;
}
.react-select__control--menu-is-open .react-select__indicators {
}

.react-select__control--menu-is-open .react-select__indicator {
  @apply bg-blue-400 h-full rounded-none !important;
  transform: rotate(180deg);
}

.react-select__indicator {
  @apply p-0;
}

.react-select__control--is-focused {
  @apply shadow-none;
  @apply border-2 outline-none;
  @apply border-blue-400;
}

.react-select__menu {
  @apply border-blue-400 border-2 border-t-0 !important;
  @apply rounded-t-none rounded-b m-0 -mt-1 pb-0 shadow-none !important;
  @apply z-50;
}

.react-select__option {
  @apply m-0 w-full;
  @apply border-b-2;
  @apply border-gray-300;
}

.react-select__option--is-focused {
  @apply bg-gnavy-100;
}

.react-select__option--is-selected {
  @apply bg-blue-400;
}

.react-select__single-value {
  @apply ml-0;
}

/* purgecss end ignore */
