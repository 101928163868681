.NewLoanCardCompact {
  @apply bg-gradient-to-t from-gblue-700 to-gblue-500 text-white;
  @apply h-40;
  @screen md {
    @apply h-full;
  }

  & .NewLoanGraphic {
    @apply absolute bottom-0 right-0;
    @media (min-width: 375px) {
      @apply mb-6;
    }
    @screen sm {
      @apply -mb-2;
    }
    & svg {
      transform: rotate(-10deg);
      @apply h-16 mr-2 mb-4;
      @media (min-width: 324px) {
        @apply h-20 mr-1 -mb-2;
      }
      @screen sm {
        @apply h-32 mr-2 mb-3;
      }
    }
  }

  & main {
    @apply px-3 py-2 flex-grow;
    & h2 {
      @apply text-sm font-semibold truncate;
      @screen sm {
        @apply text-lg;
      }
    }
    & h4 {
      @apply text-2xs font-semibold text-gblue-100;
      @screen sm {
        @apply text-xs;
      }
    }
  }

  & footer {
    @apply px-4 py-3 bg-gblue-500 font-semibold text-right text-white z-10;

    @apply text-xs truncate;
    @screen sm {
      @apply text-base;
    }
  }
}
