.LoanCardCompact {
  @apply text-gnavy-700;
  @apply bg-gradient-to-t from-gnavy-200 to-gnavy-100;
  @apply h-auto;

  & .LoanGraphic {
    @apply absolute bottom-0 right-0;
    & svg {
      @apply hidden;
      @screen sm {
        @apply block h-20 mb-2 mr-1;
        transform: rotate(-10deg);
      }
    }
  }

  & main {
    @apply px-3 py-2 flex-grow;

    @apply text-left;
    @media (min-width: 768px) {
      @apply text-left;
    }

    .LoanHeader {
      @apply flex justify-between;
      @apply text-gnavy-800 text-sm font-semibold pb-2;
      @screen sm {
        @apply text-lg;
      }
    }

    .LoanProgressBar {
      @apply h-2 rounded-full w-full bg-gnavy-300 relative flex items-stretch;
      .LoanProgressBarFilledArea {
        @apply rounded-full w-20 bg-gnavy-700;
      }
    }

    .LoanDates {
      @apply font-semibold text-gnavy-600 pt-2;
      @apply text-2xs;
      @screen sm {
        @apply text-xs;
      }
      @screen md {
        @apply text-sm;
      }
      svg {
        @apply w-3 h-3 inline mr-1 mb-px;
      }
    }

    .LoanBalance {
      @apply font-semibold mt-1;
      @apply text-base;
      @screen md {
        @apply text-lg;
      }
      & label {
        @apply text-xs tracking-wide;
        @screen sm {
          @apply text-sm;
        }
      }
    }
  }

  & footer {
    @apply px-4 py-3 bg-gnavy-100 font-semibold text-right text-gnavy-700 z-10;

    @apply text-xs truncate;
    @screen sm {
      @apply text-base;
    }
  }
}
