@import url('https://rsms.me/inter/inter.css');

/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

* {
  @apply box-border;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-family: 'Inter', sans-serif;
  @apply antialiased;
  @apply bg-gnavy-800;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

body {
  @apply min-h-screen overflow-y-auto overflow-x-hidden;

  background: linear-gradient(
      to bottom,
      rgba(44, 82, 130, 1) -30%,
      rgba(44, 82, 130, 0) 30%
    ),
    url('assets/darkbg.png');
  background-position: 0%;
  background-size: cover;
  background-repeat: no-repeat;
}

b {
  @apply font-semibold;
}

input[type='text'],
input[type='number'],
input[type='email'],
input[type='date'],
input[type='password'],
button,
select {
  /* remove inner shadow on iOS Safari */
  @apply appearance-none box-border;
  &:focus {
    @apply outline-none;
  }
}

select {
  @apply rounded p-1 border-2;
  &::-webkit-scrollbar-track-piece {
    @apply bg-transparent rounded-lg !important;
  }
  &::-webkit-scrollbar-thumb {
    @apply bg-blue-400;
  }
  &:focus {
    @apply outline-none border-gnavy-800;
  }
  &[multiple]::selection {
    @apply bg-blue-300;
  }
}

.AdminScrollbars {
  &::-webkit-scrollbar-track-piece {
    @apply bg-transparent rounded-lg !important;
  }
  &::-webkit-scrollbar-thumb {
    @apply bg-gteal-300;
  }
}

/* firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.gteal.500') theme('colors.transparent');
}

/* chrome, safari */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  @apply bg-gteal-400 rounded-full;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
::-webkit-scrollbar-button {
  @apply hidden;
}
::-webkit-scrollbar-track-piece {
  @apply bg-gnavy-800;
}
::-webkit-scrollbar-corner {
  @pply bg-transparent;
}

code {
  @apply bg-gray-200 text-blue-600;
  @apply rounded p-1 text-sm;
}
