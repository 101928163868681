.GouldsonLogo {
  @apply flex-shrink overflow-hidden py-3 mr-3;

  width: 46px;
  svg {
    @apply w-48;
  }

  @media (min-width: 375px) {
    width: auto;
    svg {
      width: 100px;
    }
  }

  @media (min-width: 414px) {
    svg {
      width: 130px;
    }
  }

  @media (min-width: 768px) {
    svg {
      width: 190px;
    }
  }
}
