.Modal {
  @apply fixed top-0 left-0 right-0 w-full h-full;
  @apply z-50;

  & .CardPadding {
    @apply mt-16;
    @apply p-4;
    @apply w-full;
    @apply flex flex-col justify-start items-center;
    @apply pointer-events-none;

    & .Card {
      @apply w-full max-w-4xl;
      @apply bg-white shadow-2xl;
      @apply pointer-events-auto;
      @apply text-gray-800;
      @apply rounded-lg;

      & section {
        @apply p-4 text-xs;

        @screen sm {
          @apply text-sm;
        }

        & h2 {
          @apply font-semibold leading-loose;
        }

        & ul {
          @apply list-disc pl-5;
        }
      }
    }
  }
}
