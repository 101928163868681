.ToggleSwitchLabel {
  @apply cursor-pointer uppercase text-xs font-semibold tracking-wide text-gray-500 select-none;
}

.ToggleSwitch {
  @apply cursor-pointer rounded-full flex items-center select-none;
  @apply border-2 border-gray-500;
  @apply w-20 p-1;
  @apply justify-start bg-gray-300;
  height: 50%;

  &.Small {
    @apply w-10 !important;
  }

  &:focus-within {
    @apply border-gnavy-800;
  }

  & .HiddenCheckBox {
    @apply opacity-0 w-0 h-0 hidden;
  }

  & .SwitchKnob {
    @apply shadow-lg bg-white rounded-full w-1/2;
    padding-bottom: 50%;
  }

  &.SwitchedOn {
    @apply justify-end bg-green-400 border-green-600;
  }
}
