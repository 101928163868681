.ModalTopBar {
  @apply fixed w-screen top-0 h-16;
  @apply bg-white p-3;
  @apply border-b-4;
  @apply shadow-lg;
  @apply z-50;

  & .ModalTopBarPadding {
    @apply max-w-4xl mx-auto flex justify-between items-center px-2 overflow-hidden;

    & h2 {
      @apply font-semibold text-xl truncate pr-2;
    }

    & .CloseButton {
      @apply text-white font-semibold text-sm shadow rounded-md px-4 py-2 cursor-pointer;

      & .Blue {
        @apply bg-blue-400;
      }
    }
  }
}
